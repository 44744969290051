import React, { useState, useEffect } from "react"
import { loadStripe } from "@stripe/stripe-js"
import Layout from "../components/layout"
// import SidebarCart from "../components/sidebarCart"
import { FiChevronUp, FiChevronDown, FiX } from "react-icons/fi"
import _ from "lodash"

import styles from "./scss/cart.module.scss"

const Cart = () => {
  const [total, setTotal] = useState(0)
  // eslint-disable-next-line
  const [cart, setCart] = useState([])
  const [arr, setArr] = useState([])

  const incrementItem = (item) => {
    const cart = arr
    if (typeof window !== "undefined") {
      cart.filter((el) => {
        if (el.sku === item.sku) {
          el.quantity++
        }
        return cart
      })
      localStorage.setItem("cart", JSON.stringify(cart))
      localStorage.setItem(
        "quantity",
        JSON.stringify(_.sumBy(cart, "quantity"))
      )

      setArr([...cart])
      calculateTotal()
    }
  }

  const decrementItem = (item) => {
    const cart = arr
    if (typeof window !== "undefined") {
      cart.filter((el, index) => {
        if (el.sku === item.sku) {
          el.quantity--
          if (el.quantity === 0) {
            cart.splice(index, 1)
          }
        }
        return cart
      })
      localStorage.setItem("cart", JSON.stringify(cart))
      localStorage.setItem(
        "quantity",
        JSON.stringify(_.sumBy(cart, "quantity"))
      )

      setArr([...cart])
      calculateTotal()
    }
  }

  const removeItem = (item) => {
    const cart = arr
    if (typeof window !== "undefined") {
      cart.filter((el, index) => {
        if (el.sku === item.sku) {
          cart.splice(index, 1)
        }
        return cart
      })
      localStorage.setItem("cart", JSON.stringify(cart))
      localStorage.setItem(
        "quantity",
        JSON.stringify(_.sumBy(cart, "quantity"))
      )
      setCart([...cart])
      calculateTotal()
    }
  }

  function calculateTotal() {
    const cart = arr
    if (typeof window !== "undefined") {
      let amount = 0
      cart.forEach((item) => {
        amount += item.price * item.quantity
      })
      setTotal(amount.toFixed(2))
    }
  }

  const stripePromise = loadStripe("pk_live_90mcUdN4jWiVF8AeEhIJdKls00T6ENgyVV")

  const redirectToCheckout = async (event) => {
    const products = arr.map((item) => ({
      sku: item.sku,
      quantity: item.quantity,
    }))
    event.preventDefault()
    const stripe = await stripePromise
    const { error } = await stripe.redirectToCheckout({
      items: products,
      successUrl: `https://brophybrothers.net/success`,
      cancelUrl: `https://brophybrothers.net/cart`,
    })

    if (error) {
      console.warn("Error:", error)
    }
  }

  useEffect(() => {
    let cart = JSON.parse(localStorage.getItem("cart"))
    if (cart !== null) {
      setArr(cart)

      let amount = 0
      cart.forEach((item) => {
        amount += item.price * item.quantity
      })
      setTotal(amount.toFixed(2))
    } else {
      setArr([])
      setTotal(0)
    }
  }, [])

  return (
    <Layout>
      {/* <SidebarCart /> */}
      <section className={`section ${styles.cart}`}>
        <div className="banner">
          <h2 className="ribbon">
            <span className="top">&nbsp;</span>
            <span className="text">Cart</span>
            <span className="bottom">&nbsp;</span>
          </h2>
        </div>

        <hr />
        <ul>
          {arr && arr.length > 0 ? (
            arr.map((item, index) => (
              <li key={index}>
                <span className={styles.itemName}>
                  {item.name}
                  <button
                    style={{ cursor: "pointer" }}
                    className={styles.remove}
                    onClick={() => removeItem(item)}
                  >
                    <FiX /> Remove
                  </button>
                </span>
                <span className={styles.quantity}>
                  {/* { item[index]} */}
                  <button
                    style={{ cursor: "pointer" }}
                    onClick={() => incrementItem(item)}
                    className={styles.increment}
                  >
                    <FiChevronUp />
                  </button>
                  <span>{item.quantity}</span>
                  <button
                    style={{ cursor: "pointer" }}
                    onClick={() => decrementItem(item)}
                    className={styles.decrement}
                  >
                    <FiChevronDown />
                  </button>
                </span>
                <span className={styles.price}>
                  <span className={styles.priceTotal}>
                    ${(item.price * item.quantity).toFixed(2)}
                  </span>
                  <span className={styles.priceEach}>${item.price} each</span>
                </span>
              </li>
            ))
          ) : (
            <li>No Items</li>
          )}
        </ul>
        <hr />
        {arr && arr.length > 0 && (
          <>
            <div className={styles.total}>
              <span>Sub total</span>
              <span className={styles.price}>${total}</span>
            </div>

            <div className={styles.checkout}>
              <button
                onClick={(evt) => redirectToCheckout(evt)}
                className={styles.btn}
              >
                Proceed to Checkout
              </button>
            </div>
          </>
        )}
      </section>
    </Layout>
  )
}

export default Cart
